import React from 'react';

export function textWithLineBreaks(text: string): React.ReactNode {
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}
